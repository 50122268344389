@import '../colors.css';
@import '../variables.css';

.IndexContainerPnp {
  .fixed-position {
    margin-top: 10px;
    z-index: 1;

    .brand-description {
      p {
        color: var(--primary-color);
        font-weight: 800 !important;
        font-size: 1.2em;

        @media (max-width: 530px)
        {
          font-size: 0.9em;
        }
      }

      em {
        color: var(--white);
      }
    }

    .actions {
      .button {
        background: var(--primary-gradient);
        color: var(--dark-blue);
        padding: 0;
        width: 370px;
        overflow:hidden;
        position:relative;
        scale: 1;

        @media (max-width: 530px) {
          width: 90%;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.4);
          width: 10%;
          height: 100%;
          top: 0;
          left: -100px;
          filter: blur(10px);
          animation-name: shine;
          animation-delay: 2000ms;
          animation-duration: 2500ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.60);
          width: 20%;
          height: 100%;
          top: 0;
          left: -100px;
          filter: blur(30px);
          animation-name: shine;
          animation-delay: 2000ms;
          animation-duration: 2500ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }

  .index-background-active + .fixed-position {
    .Carousel,
    .Features,
    .brand-description h2 {
      display: none;
    }
  }

  .PayNPlayContainer {
    margin-top: 100px;

    @media (max-width: 992px) {
      margin-top: 80px;
    }
  }

  .brand-description + .PayNPlayContainer {
    margin-top: 0;
  }

  .Features,
  .Carousel {
    .feature {
      width: 250px;

      h3 {
        color: var(--primary-color);
      }

      img {
        height: 70px;
      }

      .feature-content {
        > :first-child {
          margin: 15px 4px 4px 4px;
          text-transform: uppercase;
        }

        p {
          margin: 0;
          padding: 0 40px;
        }
      }
    }
  }

  .main-content-container {
    background-image: none;
    background-size: auto;
    background-repeat: repeat-x;

    .index__header {
      .index__header__logo {
        display: none;
        background: var(--primary-gradient);
        box-shadow: var(--box-shadow);
        border-radius: 10px;
        width: 40px;
        height: 40px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: 18px;
          background: url('../images/cancel-white.svg') no-repeat center;
        }

        img {
          display: none;
        }
      }

      .index__header__actions {
        .index__header__actions__home,
        .index__header__actions__menu {
          background: var(--primary-gradient);
          border-radius: 10px;
          height: 40px;
          width: 40px;

          img {
            padding: 5px;
          }

          &:hover {
            background: var(--primary-gradient);
          }
        }

        .index__header__actions__home {
          display: none;
        }

        .index__header__actions__menu {
          display: initial;

          img {
            filter: invert(100%) sepia(97%) saturate(6947%) hue-rotate(248deg) brightness(100%) contrast(143%);
          }
        }

        .sign-in {
          display: initial;
          margin-right: 20px;

          .button.secondary {
            display: none;
            background: none;
            box-shadow: none;
            font-style: normal;
            font-weight: 600;
            color: var(--white);
            text-decoration: underline;
            padding: 0;

            span {
              display: inline;
            }
          }
        }
      }
    }

    .brand-description {
      max-width: 600px;
      img {
        width: 250px;
      }

      h2 {
        text-shadow: 1px 5px 0px rgb(253 217 0);
        -webkit-text-stroke: 1px !important;
        -webkit-text-stroke-color: var(--dark-blue) !important;
        text-transform: uppercase;
      }
    }

    .Banners {
      background-color: var(--main-background);

      video.background {
        opacity: 0.5;
      }
    }
  }

  @media (max-width: 530px) {
    .main-content-container {
      .brand-description {
        img {
          width: 200px;
        }
      }
    }
  }

  .seo-body {
    .seo-body-content {
      width: 80%;
      margin: 0 auto;

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .index-container-full {
    min-height: 100vh;
    .seo-header {
      width: 100%;

      .seo-header-content {
        padding: 0 15px 0 15px;
        color: var(--primary-color);
      }
    }

    &.seo-header {
      padding-bottom: 155px;

      @media (max-width: 380px) {
        padding-bottom: 190px;
      }
    }
  }
}

body[data-pnp-step] .index__header__logo {
  display: block !important;
}
