@import './colors';
@import './variables.css';

.AccordionWidget {
  .AccordionItem {
    margin-top: 1px;
    border-radius: 0;
    color: var(--white);

    .account-selection {
      background: var(--tertiary-color) !important;
    }

    &:first-child {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    &:only-child {
      border-radius: var(--border-radius);
    }

    .AccordionItem__header {
      background-color: var(--secondary-color);

      &:hover {
        background-color: var(--secondary-color);
      }

      .responsiblegaming-item-header .info-amount,
      .responsiblegaming-item-header .title-text,
      .AccordionItem__expand-icon {
        color: var(--white);
      }
    }

    .AccordionItem__content {
      background-color: var(--secondary-color);

      .navigation {
        display: flex;
        justify-content: space-between;
      }
    }

    &__content {
      > div {
        padding: 8px 15px 20px;
        form {
          .ResponsibleGamingWidget__timespan {
            margin: 0 0 25px 0;
          }

          .ResponsibleGamingWidget__timespan-limit-selector {
            .tg-input {
              margin: 0 !important;

              .Input {
                input {
                  font-size: 1rem;
                  caret-color: var(--black);
                  font-weight: bold;
                  padding-bottom: 8px;
                }
              }
            }
            .button {
              width: auto;
              padding: 0 2.5em;
              display: grid;
              place-items: center;
            }
          }
        }
      }
    }
  }
}
