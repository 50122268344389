@import './colors.css';

.CasinoContainer,
.JackpotDropContainer {
  .header-and-icon {
    > span {
      font-weight: 800 !important;
    }
  }

  .grid-header {
    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .GameGrid:not(.search-grid) {
    .CasinoCategoryRow {
      .thumbnail {
        border-radius: 6px;
      }
    }

    .slick-arrow {
      &.slick-next {
        background: rgba(0, 25, 60, 0.88);

        &:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
        }
      }
      &.slick-prev {
        background: rgba(0, 25, 60, 0.88);

        &:before {
          content: '';
          position: absolute;
          height: 17px;
          width: 9px;
          background-image: url('../images/menu-arrow.svg');
          transform: rotateY(180deg);
        }
      }
    }

    .slider {
      .slide-item {
        font-size: 0.8em;
      }

      .arrow {
        color: var(--primary-color);
      }

      .last-slide a {
        background: var(--primary-gradient);
      }

      .arrow--next {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      .arrow--prev {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}
