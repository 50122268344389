button,
.primary-button,
.secondary-button,
.button {
  white-space: nowrap;
  padding: 0 3em;
  font-size: 1em;
  line-height: 3em;
  letter-spacing: -0.3px;
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  text-shadow: none;
  transition: all 0.2s ease-out;
}

button span,
  button i,
  button img,
  .primary-button span,
  .primary-button i,
  .primary-button img,
  .secondary-button span,
  .secondary-button i,
  .secondary-button img,
  .button span,
  .button i,
  .button img {
    display: inline-block;
  }

button:hover, .primary-button:hover, .secondary-button:hover, .button:hover {
    transition: all 0.2s ease-out;
  }

button.primary,
  button .primary-button,
  .primary-button.primary,
  .primary-button .primary-button,
  .secondary-button.primary,
  .secondary-button .primary-button,
  .button.primary,
  .button .primary-button {
    color: #e7e7e7;
    background: #227aba;
  }

button.primary:hover, button .primary-button:hover, .primary-button.primary:hover, .primary-button .primary-button:hover, .secondary-button.primary:hover, .secondary-button .primary-button:hover, .button.primary:hover, .button .primary-button:hover {
      background: linear-gradient(#1086bf 20%, #044372);
    }

button.secondary,
  button .secondary-button,
  .primary-button.secondary,
  .primary-button .secondary-button,
  .secondary-button.secondary,
  .secondary-button .secondary-button,
  .button.secondary,
  .button .secondary-button {
    color: #e7e7e7;
    background: #feb000;
  }

button.secondary:hover, button .secondary-button:hover, .primary-button.secondary:hover, .primary-button .secondary-button:hover, .secondary-button.secondary:hover, .secondary-button .secondary-button:hover, .button.secondary:hover, .button .secondary-button:hover {
      background: linear-gradient(#fed803, #febb00);
    }

button.tertiary, .primary-button.tertiary, .secondary-button.tertiary, .button.tertiary {
    background: #f8f8f8;
    color: #1b1b1b;
  }

button.dark, .primary-button.dark, .secondary-button.dark, .button.dark {
    color: rgb(106, 106, 106);
    font-weight: 400;
  }

button.dark:hover, .primary-button.dark:hover, .secondary-button.dark:hover, .button.dark:hover {
      color: #f8f8f8;
    }

button.tiny, .primary-button.tiny, .secondary-button.tiny, .button.tiny {
    font-size: 0.9em;
    line-height: 1em;
    padding: 8px;
    font-weight: 900;
    letter-spacing: 0.5px;
  }

button.small, .primary-button.small, .secondary-button.small, .button.small {
    font-size: 0.8em;
  }

button.large, .primary-button.large, .secondary-button.large, .button.large {
    font-size: 1.3em;
  }

button.wide, .primary-button.wide, .secondary-button.wide, .button.wide {
    display: block;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

button.icon, .primary-button.icon, .secondary-button.icon, .button.icon {
    padding: 4px;
  }

button.icon .icon, .primary-button.icon .icon, .secondary-button.icon .icon, .button.icon .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29px;
      height: 29px;
      font-size: 18px;
      background-size: 100%;
    }

button.link, .primary-button.link, .secondary-button.link, .button.link {
    font-weight: normal;
    background: none;
    padding: 0;
    text-transform: none;
    font-size: inherit;
    text-decoration: underline;
    line-height: inherit;
  }

button.link > span, .primary-button.link > span, .secondary-button.link > span, .button.link > span {
      display: inline;
    }

button.loading:not(.icon), .primary-button.loading:not(.icon), .secondary-button.loading:not(.icon), .button.loading:not(.icon) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

button.loading:not(.icon) svg, .primary-button.loading:not(.icon) svg, .secondary-button.loading:not(.icon) svg, .button.loading:not(.icon) svg {
      height: 25px;
      display: inline-block;
      margin-left: -30px;
      margin-right: 5px;
    }

button:disabled,
button[disabled] {
  cursor: not-allowed;
}
