.tg-list {
  display: flex;
  flex-direction: column;
}

.tg-list .row {
    font-size: 12px;
  }

.tg-list .row.active .detail {
        display: flex;
        position: relative;
        background: #353535;
        border-radius: 2px;
        margin: 10px 0;
        padding: 10px;
      }

.tg-list .row.active .info-expand-icon i {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
      }

.tg-list .row .info {
      display: flex;
      position: relative;
      padding: 5px 0;
      margin: 5px 0;
      -webkit-user-select: none;
              user-select: none;
    }

.tg-list .row .info.has-detail {
        cursor: pointer;
      }

.tg-list .row .info:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background: #fdc000;
      }

.tg-list .row .info .info-expand-icon {
        margin-left: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;
      }

.tg-list .row .info .info-expand-icon i {
          transition: -webkit-transform 0.2s;
          transition: transform 0.2s;
          transition: transform 0.2s, -webkit-transform 0.2s;
        }

.tg-list .row .info-item {
      flex: 1 1;
    }

.tg-list .row .info-item p {
        margin: 0;
      }

.tg-list .row .info-item:first-child {
        padding-left: 15px;
      }

.tg-list .row .info-item:last-child {
        padding-right: 15px;
      }

.tg-list .row .detail {
      padding: 0 15px;
      display: none;
      position: relative;
    }

.tg-list .row .detail:before {
        content: ' ';
        position: absolute;
        top: -6px;
        left: 20px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #353535;
      }

.tg-list .row .detail-item > * {
      margin-right: 10px;
      flex: 1 1;
    }

.tg-list .row .detail-item > *:last-child {
        overflow-wrap: anywhere;
      }

.tg-list .row .info-item,
    .tg-list .row .detail-item {
      display: flex;
      flex-direction: column;
    }

.tg-list .row .info-item .bold, .tg-list .row .detail-item .bold {
        font-size: 14px;
        font-weight: 600;
      }

.tg-list + .navigation {
    display: flex;
    justify-content: space-between;
  }

.VerifyContainer .detail-item > span {
    display: flex;
    flex-direction: column;
  }

body.ie .tg-list .row .info-item,
      body.ie .tg-list .row .detail-item {
        min-height: 40px;
      }
