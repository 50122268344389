body {
  --gameplay-topbar-height: 0px;
  --gameplay-header-height: 56px;
  --gameplay-bottombar-height: 0px;
  --gameplay-frame-darkmode-bgcolor: var(--black);
  --gameplay-popunder-overlay-bgcolor: var(--overlay);
  --gameplay-header-bgcolor: var(--primary-color);
  &.is-desktop {
    --gameplay-header-height: 60px;
    --gameplay-bottombar-height: 75px;
  }
  &.jurisdiction-sga {
    --gameplay-topbar-height: 30px;
  }
}

.PlayContainer {
  background: var(--primary-color);
  .button.icon {
    box-shadow: none;
  }

  .GamePlay {
    .GamePlayHeader {
      top:0;
      padding-top: 40px;
      background: var(--primary-gradient);
      &__back,
      &__quick-deposit {
        .button {
          background: var(--primary-btn-gradient) !important;
          box-shadow: var(--box-shadow);
        }

        span {
          color: var(--dark-blue);
          font-weight: 600;
        }
      }

      &__back {
        .button {
          &:hover {
            color: var(--primary-color-hover);
          }
        }
      }

      &__menu,
      &__menu-close {
        background: var(--primary-btn-gradient);
        box-shadow: var(--box-shadow);
      }

      &__hamburger {
        a {
          background: var(--primary-btn-gradient);
          padding: 5px;
          border-radius: 10px;
          width: 40px;
          height: 40px;
        }
        img {
          margin-top: 7px;
          height: 15px;
        }
      }

      &__quick-deposit {
        form .tg-input .Input input {
          height: 37px;
        }

        form .tg-select .Select select {
          padding-right: calc(15px + var(--trailing-lane-width));
        }

        form a {
          span {
            color: var(--white);
          }
        }

        .QuickDepositMobile {
          display: flex;
          color: var(--black);
          border-radius: 5px;
        }
      }

      &__user {
        .button {
          display: none;
        }
      }

      &__name {
        display: flex;
        margin-right: 8px;
      }

      .MembershipActions {
        .button:first-child {
          margin-right: 12px;
          margin-left: 30px;
          &::before {
            left: -33px;
          }
        }

        .button {
            color: var(--white);
          
          &:hover {
            color: var(--primary-color-hover);
          }
        }
      }
    }

    .GameDetails {
      background: var(--primary-btn-gradient);
      color: var(--white);

      &__fav {
        background: var(--primary-btn-gradient);
        color: var(--white);
      }

      &__quick-actions {
        button {
          background: var(--primary-gradient);
          color: var(--black);
          border-radius: 50%;
        }
      }

      &__categories-nav {
        overflow:hidden;
        margin-bottom: 10px;

        .CasinoCategoryMenu {
          .casino-search {
            margin-left: 0;
          }

          .categories-menu {
            &::after {
              background: none !important;
            }

            @media (max-width: 992px)
            {
              max-width: 90vw;
              padding-left: 30px;
              overflow:scroll !important; 
            }
          }
        }
      }

      &__category {
        @media (max-width: 992px) {
          margin-bottom: 50px;
          .NoGamesMessage {
            color: var(--white);
            background: var(--primary-btn-gradient);
            padding: 30px;
          }
        }
        .GameGrid {
          .slider .last-slide a {
            &:last-child {
              background: var(--primary-gradient);
            }
          }
        }
      }

      .slider {
        .arrow--next {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        .arrow--prev {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }

    .GameFrame {
      &__footer {
        display: none;
      }
    }
  }
}
