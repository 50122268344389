@import './colors.css';

.reality-check {
  color: var(--white);
  text-align: center;

  .header {
    background: var(--secondary-color) !important;
    justify-content: center;
    width: 100% !important;
  }
}

.RealityCheckContainer {
  .actions {
    .button {
      margin: 5px;

      &:disabled {
        opacity: 0.6;
      }
    }
  }
}
