@import './colors.css';
@import './variables.css';

.PlayContainer,
.CasinoContainer {
  .CasinoCategoryMenu {
    align-items: center;

    &:before {
      display: none;
    }

    /* Hide categories when searching */
    .casino-search.active + .categories-menu {
      display: none;
      width: 400px;
    }

    .casino-search {
      width: 30px;
      height: 30px;
      line-height: 40px;
      border-radius: 50%;
      transition: none;

      i {
        position: absolute;
        width: 38px !important;
        height: 38px !important;
        left: 10;
        padding: 10px;
        border-radius: 50%;
        color: var(--dark-blue);
        background: var(--secondary-btn-background-hover);
        z-index: 5;

        &:hover {
          background: var(--secondary-btn-background-hover);
        }
      }

      i.icon-close {
        &:before {
          content: '';
          background-image: url('../images/search-close.svg');
          width: 10px;
          height: 10px;
        }
      }

      .search-input {
        display: flex;
        width: 100%;

        .Input__status-line {
          display: none;
          margin-left: 30px;
        }
      }

      &.active {
        background: var(--secondary-color);
        box-shadow: none;
        border-radius: var(--border-radius);
        color: var(--white);
        height: 36px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        width: 400px;

        @media (max-width: 767px) {
          width: 100%;
        }

        input {
          color: var(--white);
          border-radius: 18px;
          padding-left: 60px;

          &::placeholder {
            color: var(--white);
          }
        }
      }
    }

    .categories-menu {
      .category-item {
        color: var(--white);
        background: none;
        height: auto;
        border-radius: 0;
        font-weight: 600;
        padding: 2px 4px;
        border-bottom: 2px solid transparent;

        &:hover {
          background: none;
        }

        &.active {
          background: unset;
          color: var(--white);
          border-bottom: 2px solid var(--white);
          font-weight: 600;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
