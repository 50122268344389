@import './colors.css';

.Footer {
  background-color: var(--main-background);
  font-style: italic;
  font-weight: 600;

  .topSection {
    background-color: var(--main-background);
  }

  .footer-logo {
    margin-top: 40px;

    img {
      height: 70px !important;
    }
  }

  div.licence {
    flex-wrap: wrap;
  }

  .licence a {
    margin: 10px;
  }
}
