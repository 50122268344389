@import './colors.css';
@import './variables.css';

.PlayContainer {
  background-color: var(--primary-color);
  background-image: url('../images/start-page-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
.GamePlay {
  background: transparent;
  .size-wrap {
    box-shadow: var(--box-shadow);
  }

  .home-button {
    background: var(--primary-btn-gradient);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    height: 40px;
    width: 40px;
    position: relative;

    &:hover {
      background: var(--primary-btn-background-hover);
    }

    i.icon-home {
      &:before {
        content: '';
        background-image: url('../images/home-icon.svg');
        background-size: 28px 28px;
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 5px;
        left: 5.5px;
      }
    }
  }

  .QuickDeposit {
    form {
      .tg-select .Select select {
        padding-right: calc(20px + var(--trailing-lane-width));
        @media (max-width: 992px) {
          padding: 6px;
        }
      }
    }
  }

  .favorite-btn {
    width: 24px;
    height: 24px;
    position: relative;

    .icon-heart-o,
    .icon-heart {
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: -3.5px;
        right: 0;
      }
    }

    .icon-heart-o {
      &:before {
        background-image: url('../images/favourited-disabled.svg');
      }
    }

    .icon-heart {
      &:before {
        background-image: url('../images/favourited-enabled.svg');
      }
    }
  }
}
