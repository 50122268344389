@import '../colors.css';

.ContentContainer {
  .actual-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--white) !important;
    }

    margin-left: 22px;
    margin-right: 10px;
  }

  .main-content-container[style] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  main.container {
    .content-container {
      padding: 20px 6% 0;
    }
  }

  .NavList ul {
    background: var(--primary-color-hover);
    border-radius: 8px;
    li {
      &:hover {
        background: var(--secondary-btn-background);

      }
      a {
        font-weight: 600;
        color: var(--dark-blue);

        &.active{
          background: var(--secondary-btn-background);
        }
      }
    }
  }
}

.TermsContainer {
  .actual-content {
    margin-left: 32px;
    margin-right: 10px;
  }
}
