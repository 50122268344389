@import './colors.css';

.Loader {
  .loader-icon {
    width: 300px;
    background-image: url('../images/logo2.png');
    background-size: 300px auto;
  }

  .loading-spinner {
    margin: 20px auto 0;
  }
}
