.ResponsibleGamingWidget__limit-content {
    position: relative;
  }

.ResponsibleGamingWidget__limit-content--valid > button {
        -webkit-animation-name: pulse;
                animation-name: pulse;
        -webkit-animation-duration: 0.3s;
                animation-duration: 0.3s;
        -webkit-animation-iteration-count: 3;
                animation-iteration-count: 3;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
      }

.ResponsibleGamingWidget__timespan,
  .ResponsibleGamingWidget__alert {
    margin: 0 0 15px 0;
  }

.ResponsibleGamingWidget__timespan-title,
  .ResponsibleGamingWidget__timespan-row,
  .ResponsibleGamingWidget__timespan-limit-selector {
    margin-bottom: 3px;
  }

.ResponsibleGamingWidget__timespan-title {
    font-size: 16px;
    font-weight: 700;
  }

.ResponsibleGamingWidget__timespan-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

.ResponsibleGamingWidget__timespan-box {
    padding: 20px;
    text-align: center;
  }

.ResponsibleGamingWidget__timespan-box > span {
      display: block;
      margin-bottom: 3px;
    }

.ResponsibleGamingWidget__timespan-box-actions {
    display: flex;
    justify-content: space-evenly;
  }

.ResponsibleGamingWidget__timespan-limit-selector {
    display: flex;
    padding: 0;
  }

.ResponsibleGamingWidget__timespan-limit-selector .tg-select,
    .ResponsibleGamingWidget__timespan-limit-selector .tg-input {
      flex: 1 1;
    }

.ResponsibleGamingWidget__timespan-limit-selector .tg-select input,
      .ResponsibleGamingWidget__timespan-limit-selector .tg-select select,
      .ResponsibleGamingWidget__timespan-limit-selector .tg-input input,
      .ResponsibleGamingWidget__timespan-limit-selector .tg-input select {
        padding-top: 8px;
        padding-bottom: 8px;
      }

.ResponsibleGamingWidget__timespan-limit-selector .tg-select__status-text, .ResponsibleGamingWidget__timespan-limit-selector .tg-input__status-text {
        display: none;
      }

.ResponsibleGamingWidget__timespan-limit-selector .button {
      margin: 0 0 0 0.5em;
      width: 46px;
    }

.ResponsibleGamingWidget .Loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.9);
  }

.ResponsibleGamingWidget .AdminLimits {
    margin-bottom: 10px;
  }

.ResponsibleGamingWidget .AdminLimits .Alert {
      margin: 0;
    }

@-webkit-keyframes pulse {
  0% {
    background: #fdc000;
  }

  50% {
    background: #fed803;
  }

  100% {
    background: #fdc000;
  }
}

@keyframes pulse {
  0% {
    background: #fdc000;
  }

  50% {
    background: #fed803;
  }

  100% {
    background: #fdc000;
  }
}
