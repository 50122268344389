.Popup {
  &__header {
    color: var(--main-background);
  }

  &__content {
    overflow: auto;
    font-style: italic;
    color: var(--black);
    border-radius: var(--border-radius);
  }
}
