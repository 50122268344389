@import './colors.css';
@import './variables.css';

button,
.primary-button,
.secondary-button,
.button,
.bonus-code-form,
.Accordion .button,
.responsiblegaming-limit-selector
  .AccordionItem
  .AccordionItem__content
  .responsiblegaming-limit-item-content
  .limit-container
  .button,
.responsiblegaming-signup .button {
  border-radius: 1.5em 0.5em 1.5em 0.5em;
  text-transform: uppercase;
  color: var(--white);
  font-weight: 800 !important;
  font-style: italic;

  &.primary,
  .primary-button,
  &.secondary,
  .secondary-button,
  &.tertiary,
  .tertiary-button {
    background: var(--primary-btn-gradient);
    box-shadow: var(--box-shadow);
    color: var(--white);
    transition: none;

    &:hover {
      background: var(--primary-btn-background-hover);
      color: var(--primary-color-hover);
    }
  }

  &.selected {
    background: var(--primary-btn-background-hover) !important;
  }

  &.loading {
    svg {
      circle {
        stroke: var(--white) !important;
      }
    }
  }
}
